'use client';

import { Block } from '../../components/Block';
import { Gutter } from '../../components/Gutter';
import { TypographyH2 } from '@/components/ui/h2';
import { motion } from 'framer-motion';
import { TypographyH3 } from '@/components/ui/h3';
import { TypographyLead } from '@/components/ui/lead';
import Link from 'next/link';
import { ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { FC } from 'react';

const About = () => {
	return (
		<Block animate className='bg-card  border-t border-b border-border'>
			<Gutter padding='large' className='h-full items-center'>
				<div className='flex flex-col text-center gap-x-10 mx-auto max-w-4xl w-fit'>
					<TypographyH2 className='w-fit mx-auto'>Expertize</TypographyH2>
					<TypographyH3>Trusted by Healthcare Professionals</TypographyH3>
					<TypographyLead>
						OMNIUX Healthcare stands as a collective of seasoned experts, each
						with extensive experience in collaborating with medical institutions
						across the United States.
					</TypographyLead>
					<Button
						className='w-fit mx-auto mt-8'
						variant='outline'
						el='link'
						href='#case-studies'>
						Learn more
					</Button>
				</div>
			</Gutter>
		</Block>
	);
};

export default About;
