import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/blocks/About/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/blocks/Details/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/blocks/Hero/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/blocks/LogoCloud/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/blocks/Services/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/blocks/Stats/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Block"] */ "/vercel/path0/src/components/Block/index.tsx");
