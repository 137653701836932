'use client';

import { Block } from '../../components/Block';
import { Gutter } from '../../components/Gutter';
import { Button } from '../../components/ui/button';
import { TypographyH1 } from '../../components/ui/h1';
import { TypographyLead } from '../../components/ui/lead';
import { LazyMotion, m } from 'framer-motion';

const loadFeatures = () =>
	import('../../feature.js').then((res) => res.default);

const Hero = () => {
	return (
		<Block className=' from-neutral-900 bg-gradient-to-b to-background'>
			<Gutter padding='hero' className='flex justify-between items-center'>
				<div className='w-full items-center flex flex-col space-y-8 text-center py-16 lg:py-32'>
					<LazyMotion features={loadFeatures}>
						<div className='overflow-hidden h-full'>
							<m.div
								initial={{ opacity: 0, y: '100%' }}
								animate={{
									y: 0,
									opacity: 1,
									transition: { duration: 0.8, ease: [0.61, 0.01, 0.3, 0.99] },
								}}>
								<TypographyH1>
									<span className='items-start text-center max-w-3xl hidden lg:block'>
										Door-to-discharge with your patient
										<m.span
											className='relative'
											viewport={{
												once: true,
											}}
											initial={{ color: 'white' }}
											whileInView={{
												color: '#B8FA76',
												transition: { delay: 1.3 },
											}}>
											&nbsp;at the center.
											<m.span
												initial={{ scaleX: 0, originX: 0 }}
												whileInView={{
													scaleX: [0, 1, 1, 0],
													originX: [0, 0, 1, 1],
												}}
												viewport={{ once: true }}
												transition={{
													delay: 0.7,
													duration: 1,
													ease: 'easeInOut',
												}}
												className='absolute top-1 left-1 w-full h-full bg-accent rounded flex items-center justify-center'
											/>
										</m.span>
									</span>

									<span className='items-start text-center max-w-3xl lg:hidden'>
										Door-to-discharge with your patient
										<span className='text-accent'>&nbsp;at the center.</span>
									</span>
								</TypographyH1>
							</m.div>
						</div>
					</LazyMotion>
					<TypographyLead className='pb-8 animate-in fade-in duration-1000'>
						The turn-key service consultancy empowering healthcare networks for
						excellence
					</TypographyLead>
					<div className='flex gap-x-4'>
						<Button
							href='#services'
							el='link'
							variant='default'
							className='group'>
							Explore services
						</Button>
						<Button
							variant='secondary'
							el='link'
							href='/contact'
							className='group'>
							Contact us
						</Button>
					</div>
				</div>
			</Gutter>
		</Block>
	);
};

export default Hero;
